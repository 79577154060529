<template>
  <div class="footer">
    <div class="top">
      <div class="left">
        <div class="logo">
          <img src="@/assets/logo.png" alt />
        </div>
        <div class="address-label">公司地址</div>
        <div class="address">河北省邯郸市邯郸工业设计中心</div>
      </div>
      <div class="center">
        <div class="service">
          <div
            class="item"
            v-for="item in list.service"
            :key="item.id"
            :class="{ active: currentIndex == item.id }"
            @click="changeIndex(item.id, item.path)"
          >{{ item.name }}</div>
        </div>
        <div class="about">
          <div
            class="item"
            v-for="item in list.about"
            :key="item.id"
            :class="{ active: currentIndex == item.id }"
            @click="changeIndex(item.id, item.path)"
          >{{ item.name }}</div>
        </div>
        <div class="call">
          <div
            class="item"
            v-for="item in list.call"
            :key="item.id"
            :class="{ active: currentIndex == item.id }"
            :style="{
              textDecoration: item.show == true ? 'underline' : 'none',
            }"
            @click="changeIndex(item.id)"
          >{{ item.name }}</div>
        </div>
      </div>
      <div class="right">
        <div class="person">
          <div class="rq">
            <img src="@/assets/img/person-rq.png" alt />
          </div>
          <div class="title">个人版app</div>
        </div>
        <div class="compony">
          <div class="rq">
            <img src="@/assets/img/compony-rq.png" alt />
          </div>
          <div class="title">企业版app</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="desc">
        <span>邯郸亿帧戏文化传媒有限公司</span>
        <a href="https://beian.miit.gov.cn/" class="open" target="_blank">冀ICP备2024067107号-1</a>
        <a
          href="https://beian.mps.gov.cn/#/query/webSearch?code=13040302001639"
          class="opens"
          target="_blank"
        >
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAFQklEQVRYw+3Wa1BUdRjH8SOpMeg4WhZGpDIxiaaTeUFgWrxE4AVRQJGlRRAVIV1JkbgMgQLi5AVBQSVLSp0xlEAUKBEEFZCrCstll8UV2AV2YbmoGCrYv31+R95UL5pmmtamZ+bz6rz5nvOc/5zDcX9jGLs/iTxuyvIlWYkRFeTHA2HVRFtzfhthTG5KuH96/vUgNlC4mMgyw1NJit/aAXLKazYje9xtIMZ/OZz50gW+9hcNkvoLEemEPbnrSP47QYwxQ5Ifv54RqzcXwFFvSyjaOhfavN8F7Y5ZcC/HH9JOB4LNa9Zw5YA76OZV8vIGMdZtSp7cDrtOnOavYiQhTAiPwi1AMtIQaqyngsxpBtw2GAGDKfaQmpUAa6xc4Vfp4UtEdzAMycsT9JQ1Tyctl/2eEkuTlYysF/rCUNxMqDEzgTqzSXBnpgnIHCzgjvEEuD52DLBr3rA1MAaWmNtB582wdtIljZ9G9D+IPU6aTxIPBjHCcXvg3CEh9K2fDLWvjIH6D6fwTIyheuwEqLUyhzLOALq8pkN+bgRw3HY4FBsMzxojZxP9DequLjAlQwVrbpIjhyIY4UYGQ/buhdBqPxlk3Gion2IMDQIz3kJe/ZS34I7uHkmD7VSQVgYDNyIAwsNCgfXGXoOBPjP9DKrOCAogA2etGTmTHAMcFwFZye7wS5QlVHGjoEw4A2qPCUBZ6AzNcQ5Q/YYRdO+YB1U3dsDwypLio4FJ3ECryIzWz6Cm3NgTRHN8HiPF6eHAGSbAdh8feFZkB7krzaHE9h2o85sDsiAbkIsXQMN+e2CtGyF0kzdwXCgU5++D/ouLQFV4OEU/g2Q/iNuIPNaKkQflAWBqexxGjhLDVUcL6IwSQN3SGVChe6FJg9dckCx6D1QBliDZLIAxo7eA8eyv4KE0BJqTrHkZvnL9DJKn+Twmt0NsGGHZy2Dn3kQYfsQ53Hh4/r4RNGz8AIpdzKEuaAF0RC2E57MmQgE3ATjuM/CPiANW7AqSfQJQ5vk362eQKmd3JrmXsoSRocpNIMnbB9zbceDIWUPmuHFQNMkISqa9DpUvNK6YDpW2s8DfwBK48WFQnhMCgzUBoLy0BrRVe5P0NWjPLdKUsJiR1tR1wGp8IeZwMgx/SrgRvjxuAziNcwLvyathLOcJHLflhRDYGRYFrNET2rJ5yvPLoas0tOj/oL8UpC4JHyTSU+6MNCS4gvKoAB5WiKG+MAQSg0WwLXQ/ZJ3xhao0FxB5hYCbUwAEfhEF3Td8QP2dAOQnPwFlxgrolUVq9TPoaX+ZB2nLc2Gk6awj1MU78HZZwJMid2Byb550JQwVO0NfxlJgdz14vWKeRAiK6DlQF28PLZdcoLNcBIO92bb6GTQ8Q/13RURT6tlH2gvXMlITLYD6uI+gp2ozdF0VQXumM6ivCqGvahM8kPiDItkeGo8tB025GFQ3xFrSr06zI3/4yde7oN7m0sWk5eKWDqK5JWJQvAHac9ygq3Adr9gTNNc3QG85rzPfHe5/7wDtPwuhp/Zz6CjyhaZzwi6ivfetHdH/oP77+3PJQOsuRnqkQdCa4wWqyx6gyecpL64GTaEX7ycXUJz4GJp1B4O0X/Hg0Xp1tFV+8Ei1k6c5coHofxBrrzQinbKYo0SVJ+wn6iurGHlY5gY911aDJnMFaHXXiDp9GQyvtKfUA9QFTtBZ7gPdit0tpFd9OpwwFmlA9D/o9yNLDpxIKmI8PMnNSNtviCLVpYTITzrXEGWaq4qos0WgOPdpCenIF+eRrurjB4k0PXopYZG6gMg/D/gNBUxhAbSAmKMAAAAASUVORK5CYII="
          alt
          class="beian"
        />
        冀公网安备13040302001639号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: {
        service: [
          {
            id: '01',
            name: '特色服务',
          },
          {
            id: '02',
            name: '全民剧作',
            path: '/drama',
          },
          {
            id: '03',
            name: '短期招聘',
            path: '/recruit',
          },
          {
            id: '04',
            name: '人才学院',
            path: '/talent',
          },
          {
            id: '05',
            name: '设备租赁',
            path: '/qclease',
          },
          {
            id: '06',
            name: '经纪管理',
            path: '/economy',
          },
          {
            id: '07',
            name: '宣发上映',
            path: '/platform',
          },
        ],
        about: [
          {
            id: '11',
            name: '关于我们',
            path: '/about',
          },
          {
            id: '12',
            name: '了解我们',
            path: '/about',
          },
          {
            id: '13',
            name: '价值与理念',
            path: '/about',
          },
          {
            id: '14',
            name: '加入我们',
            path: '/addo',
          },
        ],
        call: [
          {
            id: '21',
            name: '联系我们',
          },
          {
            id: '22',
            name: '0310-7058999',
          },
          {
            id: '23',
            name: 'hdyizhenxi@163.com',
            show: true,
          },
        ],
      },
      currentIndex: '01',
    }
  },
  watch: {
    '$route' (to, form) {
      switch (to.path) {
        case '/drama': this.currentIndex = "02"; break
        case '/recruit': this.currentIndex = "03"; break
        case '/talent': this.currentIndex = "04"; break
        case '/qclease': this.currentIndex = "05"; break
        case '/economy': this.currentIndex = "06"; break
        case '/economy': this.currentIndex = "06"; break
        case '/platform': this.currentIndex = "07"; break
        case '/about': this.currentIndex = "11"; break
        default: this.currentIndex = "01"; break
      }
    }
  },
  methods: {
    changeIndex (id, path) {
      this.currentIndex = id
      if (path != undefined) {
        window.scrollTo(0, 0)
        this.$emit('about')
        this.$nextTick(() => {
          // 导航到目标路由
          this.$router.push(path)
        })
      }
    },
    childMethod () {
      // console.log('子组件方法被触发了');
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  font-weight: 500 !important;
  font-size: 1rem !important;
  color: #fff !important;
}
.footer {
  width: 100vw;
  height: 30.1875rem;
  background-color: #252525;
  padding: 5.25rem 16.25rem 0;
  box-sizing: border-box;
  position: relative;
  .top {
    display: flex;
    .left {
      .logo {
        width: 8.1875rem;
        height: 2.875rem;
        img {
          width: 8.1875rem;
          height: 2.875rem;
        }
      }
      .address-label {
        margin-top: 2.4375rem;
        font-size: 1rem;
        color: #fff;
        font-weight: 500;
      }
      .address {
        margin-top: 0.9375rem;
        font-size: 0.875rem;
        color: rgba(255, 255, 255, 0.5);
        font-weight: 400;
      }
    }
  }
  .center {
    margin-left: 10.8125rem;
    display: flex;
    width: 30vw;
    justify-content: space-between;
    .service,
    .about,
    .call {
      .item {
        font-size: 0.875rem;
        color: rgba(255, 255, 255, 0.5);
        font-weight: 400;
        margin-bottom: 0.9375rem;
        width: 10.625rem;
        height: 1.125rem;
      }
    }
  }
  .right {
    display: flex;
    margin-left: 10.8125rem;
    .person,
    .compony {
      .rq {
        width: 5.8125rem;
        height: 5.8125rem;
        img {
          width: 5.8125rem;
          height: 5.8125rem;
        }
      }
      .title {
        margin-top: 0.625rem;
        text-align: center;
        font-size: 0.875rem;
        color: rgba(255, 255, 255, 0.5);
        font-weight: 400;
      }
    }
    .compony {
      margin-left: 1.875rem;
    }
  }
  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 4.4375rem;
    background-color: rgba(255, 255, 255, 0.03);
    padding: 1.1875rem 0 0rem 37.125rem;
    .desc {
      color: #fff;
      font-style: 0.875rem;
      font-weight: 400;
      :first-child span {
        margin-right: 0.06rem;
      }
      .open {
        margin: 0 0.6rem;
        cursor: pointer;
        color: #ffffff;
      }
      .opens {
        // margin: 0 0.6rem;
        cursor: pointer;
        color: #ffffff;
      }
      .opens {
        cursor: pointer;
        color: #ffffff;
      }
      .beian {
        margin: 0 0.2rem;
        //width: 0.19rem;
        height: 1.2rem;
      }
    }
  }
}
</style>
