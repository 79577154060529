import { cosDownload } from '@/utils/cosUtils';
export default {
  inserted: async function (el, binding) {
    // console.log('v-cosurl', 'inserted', binding.value.src, el, el.children[0])
    if (binding.value.src) {
      if (el.children && el.children[0]) { //el-image 获取不到  
        el.children[0].src = await cosDownload(binding.value.src);
      } else { //img
        el.src = await cosDownload(binding.value.src);
      }
    } else if (binding.value.errorSrc) {
      el.src = binding.value.errorSrc;
    }
    // console.log(el, el.src, binding, await cosDownload(binding.value.src));
  },
  update: () => {
    // console.log('v-cosurl', 'update');
  },
  componentUpdated: async function (el, binding) {
    // console.log('v-cosurl', 'componentUpdated')
    if (binding.value.src) {
      el.src = await cosDownload(binding.value.src);
    } else if (binding.value.errorSrc) {
      el.src = binding.value.errorSrc;
    }
  },
}