import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Form, FormItem, Input, Select, Option, DatePicker, Button, Upload, RadioGroup, Radio, TimePicker, Checkbox, MessageBox, Message, Pagination } from 'element-ui'
import directive from "@/directive"
Vue.component(Form.name, Form)
Vue.component(FormItem.name, FormItem)
Vue.component(Input.name, Input)
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)
Vue.component(DatePicker.name, DatePicker)
Vue.component(Button.name, Button)
Vue.component(Upload.name, Upload)
Vue.component(RadioGroup.name, RadioGroup)
Vue.component(Radio.name, Radio)
Vue.component(TimePicker.name, TimePicker)
Vue.component(Checkbox.name, Checkbox)
Vue.component(Pagination.name, Pagination)
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm

import "@/assets/css/base.css"
import "@/assets/js/flexable.js"

// 图片懒加载
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)

Vue.config.productionTip = false

import Footer from "@/components/Footer.vue"
import Header from "@/components/Header.vue"
import Solve from "@/components/Solve.vue"
Vue.component('Footer', Footer)
Vue.component('Header', Header)
Vue.component('Solve', Solve)
Vue.use(directive)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
