import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',
    name: 'Index',
    redirect: '/index',
    component: () => import('@/views/index/Index.vue'),
    children: [
      {
        path: '/index',
        name: 'Home',
        component: () => import('@/views/home/Index.vue'),
      },
      {
        path: '/drama',
        name: 'Drama',
        component: () => import('@/views/drama/Index.vue'),
      },
      {
        path: '/recruit',
        name: 'Recruit',
        component: () => import('@/views/recruit/Index.vue'),
      },
      {
        path: '/kpb',
        name: 'Kpb',
        component: () => import('@/views/kpb/Index.vue'),
      },
      {
        path: '/qclease',
        name: 'qclease',
        component: () => import('@/views/qclease/Index.vue'),
      },
      {
        path: '/jdlease',
        name: 'jdlease',
        component: () => import('@/views/zdlease/Index.vue'),
      },
      {
        path: '/cinema',
        name: 'cinema',
        component: () => import('@/views/cinema/index.vue'),
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('@/views/news/index.vue'),
      },
      {
        path: '/settle',
        name: 'settle',
        component: () => import('@/views/settle/index.vue'),
      },
      {
        path: '/addo',
        name: 'addo',
        component: () => import('@/views/addo/index.vue'),
      },
      {
        path: 'details',
        name: '/details',
        component: () => import('@/views/news/details'),
      },
      {
        path: '/shadow',
        name: 'shadow',
        component: () => import('@/views/shadow/index.vue'),
      },
      {
        path: '/talent',
        name: 'talent',
        component: () => import('@/views/talent/Index.vue'),
      },

      {
        path: '/platform',
        name: 'platform',
        component: () => import('@/views/platform/index.vue'),
      },
      {
        path: '/booking',
        name: 'booking',
        component: () => import('@/views/platform/booking.vue'),
      },
      {
        path: '/economy',
        name: 'economy',
        component: () => import('@/views/economy/index.vue'),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/about/Index.vue'),
      },
      {
        path: '/need',
        name: 'need',
        component: () => import('@/views/zdlease/need.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

// router.beforeEach((to, from, next) => {
//   // 检查是否要滚动到顶部
//   if (to.path === '/about') {
//     next((vm) => {
//       // 假设 vm 是 Vue 实例（在 Vue 3 中，这不一定总是成立）
//       // 在这里执行滚动到顶部的操作
//       window.scrollTo(0, 0);
//     });
//   } else {
//     next();
//   }
// });

export default router;
