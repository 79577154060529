import axios from 'axios'
console.log(process.env.VUE_APP_HTTP_URL, "55555")

export function request (config) {
    const instance = axios.create({
        baseURL: process.env.VUE_APP_HTTP_URL, // url = base url + request url
        timeout: 5000
    })
    instance.interceptors.request.use(config => {
        // const token = localStorage.getItem("token")
        // config.headers.token = `${token}`
        return config
    }, err => {

    })
    instance.interceptors.response.use(res => {

        if (res.headers.key != undefined) {
            sessionStorage.setItem("key", res.headers.key)

        }
        // const { token } = res.headers
        // token && localStorage.setItem("token",token)
        return res.data
    }, err => {
        const { status } = err.response

        if (status === 401) {
            localStorage.removeItem("token")
            localStorage.removeItem("userInfo")
            window.location.href = "/"
        }
    })
    return instance(config)
}