import cosurl from './v-cosurl.js'

const directive = {
  install: Vue => {
    // 注册全局自定义指令
    Vue.directive('cosurl', cosurl)
    // 注册全局自定义指令

  }
}

// 导出组件
export default directive
