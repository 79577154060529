<template>
  <div class="header">
      <img :src="data.bcImg" alt="" />
      <div class="eng">
        {{ data.eng }}
      </div>
      <div class="title">
        {{ data.title }}
      </div>
      <div class="sub-title">
        {{ data.sub_title }}
      </div>
      <div class="desc">
        {{ data.desc }}
      </div>
      <div class="btn" @click="goForm">
        预约使用
      </div>
    </div>
</template>

<script>
export default {
  props: ['data'],
  methods: {
    goForm(){
      this.$router.push("booking")
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
    width: 100vw;
    height: 60.625rem;
    position: relative;
    img {
      width: 100vw;
      height: 60.625rem;
    }
    .eng{
      position: absolute;
      left: 16.25rem;
      top: 14.375rem;
      font-size: .75rem;
      font-weight: 400;
      color: #fff;
    }
    .title{
      position: absolute;
      left: 16.25rem;
      top: 19.75rem;
      font-size: 4.0625rem;
      color: #fff;
      font-weight: bold;
      width: 37.1875rem;
    }
    .sub-title{
      position: absolute;
      left: 16.25rem;
      top: 29.1875rem;
      font-size: 2.5625rem;
      color: #fff;
      font-weight: 400;
      width: 46.125rem;
      height: 10rem;
      line-height: 5rem;
    }
    .desc{
      position: absolute;
      left: 16.25rem;
      top: 34.5625rem;
      font-size: .875rem;
      color: #fff;
      font-weight: 400;
      width: 54.25rem;
    }
    .btn{
      position: absolute;
      left: 16.25rem;
      top: 45.8125rem;
      width: 12.1875rem;
      height: 3.5625rem;
      background: #A28847;
      font-size: 1.125rem;
      color: #fff;
      font-weight: bold;
      text-align: center;
      line-height: 3.5625rem;
      border-radius: .25rem;
    }
  }
</style>