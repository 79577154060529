<template>
  <div class="solve">
    <div class="title">亿帧智能CG后期解决方案</div>
    <div class="img">
      <img src="@/assets/img/solve/solve-bg.png" alt="" />
      <div class="list">
        <div
          class="item"
          v-for="item in list"
          :key="item.id"
          @click="changeCard2(item.id)"
        >
          <img :src="item.imgUrl" v-if="currentIndex == item.id" alt="" />
          <img :src="item.url" v-else alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      time2: null,
      currentIndex: '10',
      list: [
        {
          id: '10',
          imgUrl: require('@/assets/img/solve/solve-1.png'),
          url: require('@/assets/img/solve/s-1.png'),
        },
        {
          id: '11',
          imgUrl: require('@/assets/img/solve/solve-2.png'),
          url: require('@/assets/img/solve/s-2.png'),
        },
        {
          id: '12',
          imgUrl: require('@/assets/img/solve/solve-3.png'),
          url: require('@/assets/img/solve/s-3.png'),
        },
        {
          id: '13',
          imgUrl: require('@/assets/img/solve/solve-4.png'),
          url: require('@/assets/img/solve/s-4.png'),
        },
        {
          id: '17',
          imgUrl: require('@/assets/img/solve/solve-5.png'),
          url: require('@/assets/img/solve/s-5.png'),
        },
        {
          id: '16',
          imgUrl: require('@/assets/img/solve/solve-6.png'),
          url: require('@/assets/img/solve/s-6.png'),
        },
        {
          id: '15',
          imgUrl: require('@/assets/img/solve/solve-7.png'),
          url: require('@/assets/img/solve/s-7.png'),
        },
        {
          id: '14',
          imgUrl: require('@/assets/img/solve/solve-8.png'),
          url: require('@/assets/img/solve/s-8.png'),
        },
      ],
    };
  },
  mounted() {
    this.animalStart2();
  },
  methods: {
    changeCard2(id) {
      this.currentIndex = id;
      clearInterval(this.timer2);
      this.animalStart2();
    },
    animalStart2() {
      this.timer2 = setInterval(() => {
        if (this.currentIndex == 17) {
          this.currentIndex = 9;
        }
        this.currentIndex++;
      }, 2000);
    },
  },
  destroyed() {
    clearInterval(this.timer2);
  },
};
</script>

<style lang="scss" scoped>
.solve {
  width: 100vw;
  height: 51.875rem;
  padding: 6.25rem 16.25rem;
  box-sizing: border-box;
  .title {
    font-size: 1.625rem;
    font-weight: bold;
    color: #000;
    text-align: center;
  }
  .img {
    margin-top: 3.75rem;
    width: calc(100vw - 34.5rem);
    height: 33.625rem;
    position: relative;
    img {
      width: calc(100vw - 34.5rem);
      height: 33.625rem;
    }
    .list {
      position: absolute;
      left: 11.8rem;
      top: -0.0625rem;
      display: flex;
      flex-wrap: wrap;
      .item {
        margin-bottom: 1.875rem;
        img {
          width: 17.425rem;
          height: 15.8125rem;
        }
      }
      :nth-child(2),
      :nth-child(3),
      :nth-child(4),
      :nth-child(6),
      :nth-child(7),
      :nth-child(8) {
        margin-left: -2.6125rem;
      }
    }
  }
}
</style>
